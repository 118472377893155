.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --amplify-primary-color: #007cb0;
  --amplify-secondary-color: #ffffff;
  --amplify-primary-tint: #007cb0;
  --amplify-primary-shade: #007cb0;
  --amplify-primary-contrast: #ffffff;
  --amplify-background-color: #303030;
  --amplify-secondary-contrast:rgba(255, 255, 255, 0.09);
  --amplify-light-grey:#303030;
}

@import "~handsontable/dist/handsontable.full.css";
td.custom-cell {
  color: #fff;
  background-color: #37bc6c;
}
.custom-table thead th:nth-child(even),
.custom-table tbody tr:nth-child(odd) th {
  background-color: #d7f1e1;
}

.custom-table {
  height: 100%;
  width: 100%;
}

.ht_master {
  height: 100%;
  width: 100%;
}

.wtHolder {
  height: 100%;
  width: 100% !important;
}

.handsontable td,
.handsontable th {
  border-top-width: 10px;
  border-left-width: 10px;
  border-right: 1px solid #303030;
  border-bottom: 1px solid #303030;
  height: 30px;
  empty-cells: show;
  line-height: 21px;
  padding: 0 4px;
  background-color: #303030;
  vertical-align: top;
  overflow: hidden;
  outline-width: 0;
  white-space: pre-line;
  background-clip: padding-box;
  font-size: 0.875rem;
}

.router-link {
  text-decoration: none;
}
